.services h1 {
  font-weight: 600;
  font-size:38px;
  font-family: 'Roboto', sans-serif; }

.nav-tabs .nav-link {
  margin-bottom: 0;
  border: none;
  padding: 0.5rem 1rem;
  color: #000000;
  font-size: 20px;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #F5BF23;
  /* color: #F5BF23; */
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  display: block;
  transition: transform 0.3s ease-out;
}

.image-container:hover img {
  transform: scale(1.2);
}

@media (max-width: 992px) {
  .nav-tabs .nav-link {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .image-container {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .nav-tabs .nav-item {
    flex: 1 0 50%;
    max-width: 50%;
    text-align: center;
  }
}
