/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 0, 0.3); /* Light yellow border */
    border-top: 8px solid rgb(245, 191, 35); /* Solid yellow top border */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px; /* Space between spinner and text */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-container h1 {
    color: rgb(245, 191, 35);
  }
  