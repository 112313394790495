.card-item {
    display: flex;
    flex-direction: row;
    align-items: stretch; 
    width: 100%;
    box-sizing: border-box;
    height: 100%; 
}

.card-title {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    transition: border-color 0.3s ease, color 0.3s ease;
}

.geeks {
    flex: 1; 
    overflow: hidden; 
}

h1 {
    font-weight: 600;
    font-size: 38px;
    font-family: 'Roboto', sans-serif;
}

.construction-image {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    transition: 0.2s all ease-in-out;
}

.card-item:hover .construction-image {
    transform: scale(1.2);
}


@media (max-width: 700px) {
    .card-item {
        flex-direction: column; 
        height: auto; 
    }

    .geeks {
        width: 100%;
    }

    .construction-image {
        width: 100%;
        height: auto; 
    }

    .card-body {
        width: 100%;
        padding: 20px;
        overflow: auto; 
        box-sizing: border-box;
    }
}


@media (min-width: 700px) and (max-width: 1200px) {
    .card-item {
        flex-direction: column; 
        height: auto; 
    }

    .geeks {
        width: 100%;
    }

    .construction-image {
        width: 100%;
        height: auto; 
    }

    .card-body {
        width: 100%;
        padding: 20px;
        overflow: auto; 
        box-sizing: border-box;
    }
}


@media (min-width: 1200px) {
    .card-item {
        flex-direction: row; 
        height: 100%; 
    }

    .geeks {
        flex: 1; 
    }

    .construction-image {
        width: 100%;
        height: 100%; 
    }

    .card-body {
        width: 100%;
        padding: 20px;
        overflow: auto; 
        box-sizing: border-box;
    }
}
