.service-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.center-section {
  text-align: left;
  width: 100%;
}

.left-section {
  width: 100%;
  text-align: left;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.gallery-item {
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}
