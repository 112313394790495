.geeks {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.blog-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card:hover .blog-image {
  transform: scale(1.2);
}

.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination .page-link {
  color: #f5bf23;
  border-color: black;
}

.pagination .page-item.active .page-link {
  background-color: #f5bf23;
  border-color: black;
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 10%;
}

.card-text {
  flex: 1;
}

.card-title {
  margin-bottom: 10px;
  font-weight: bold;
}

.card-author {
  color: #666;
}
