/* Hero */
.hero-container {
  padding-top: 60px;
  margin-bottom: 50px;
}

.carousel-img {
  height: 800px;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-text {
  width: 80%;
  max-width: 500px;
  text-align: center;
  word-wrap: break-word;
}

.carousel-text h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}

.carousel-text p {
  font-size: 0.875rem;
  color: #fff;
}
.custom-btn {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.custom-btn:hover {
  background-color: #f5bf23;
  border: 2px solid#F5BF23;
  color: black;
}

@media (min-width: 576px) {
  .carousel-text {
    width: 60%;
  }

  .carousel-text h1 {
    font-size: 1.5rem;
  }

  .carousel-text p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .carousel-text {
    width: 50%;
  }

  .carousel-text h1 {
    font-size: 2.5rem;
  }

  .carousel-text p {
    font-size: 1.125rem;
  }
}

@media (max-width: 575.98px) {
  .carousel-text h1 {
    font-size: 1.5rem;
  }

  .carousel-text p {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .carousel-text h1 {
    font-size: 1.5rem;
  }

  .carousel-text p {
    font-size: 1rem;
  }
}

@media (max-width: 360px) {
  .carousel-text h1 {
    font-size: 1rem;
  }

  .carousel-text p {
    font-size: 0.75rem;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.carousel-text {
  animation: slideInFromBottom 0.5s ease-out;
}

.card-bg img:hover {
  transform: scale(1.1);
}

@media (max-width: 1200px) {
  .card-bg img {
    width: 210px;
    height: 160px;
  }
}

@media (max-width: 576px) {
  .card-bg img {
    width: 210px;
    height: 160px;
  }
}

@media (max-width: 576px) {
  .carousel-text {
    width: 75%;
  }
}

.first-ser {
  display: flex;
  flex-direction: row;
}

/* About-Us */
.about-us-image-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.about-us-image-container img {
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.about-us-image-container:hover img {
  transform: scale(1.2);
}

/* Projects */
.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.tab-buttons button {
  padding: 10px 20px;
  margin: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.tab-buttons button:hover {
  background-color: #156fd6;
}

.tab-buttons button.active-tab {
  background-color: #156fd6;
  color: white;
}

.project-container {
  width: 100%;
  margin-top: 100px;
}

.project-container-child {
  padding: 0px auto;
}

.image-gallery {
  width: 75%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.image-gallery img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.image-gallery img:hover {
  transform: scale(1.1);
}
.image-container {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 20px;
  pointer-events: none;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

/* Testimonials */
.testimonials {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.testimonials h1 {
  margin-bottom: 20px;
  font-weight: 700;
  margin-top: 20px;
}

/* footer */
.footer-section,
.footer-child {
  background-color: black;
}

.hover-underline-white:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.custom-quill .ql-editor {
  min-height: 300px; /* Adjust this value to increase the height */
}
