body {
  margin: 0px;
  padding: 0px;
  background-color: #fffaf4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Ubuntu, sans-serif !important;
}